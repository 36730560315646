import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";
import aboutUsBackgroundImage from "src/images/aboutUsBackgroundImage.jpg";
import faqBackgroundImage from "src/images/faqBackgroundImage.jpg";
import growthBackgroundImage from "src/images/growthBackgroundImage.jpg";
import landingBackground from "src/images/landingBackground.jpg";
import propertyReportsBackground from "src/images/propertyReportsBackground.jpg";

export const onAllPages = [
  {
    name: "Home First Time Modal",
    moduleComponentName: "FirstTimeModal",
    moduleProps: { pageName: "logan-plan-2025", targetModalId: "intro" },
  },
  {
    name: "Feeback float",
    moduleComponentName: "FeedbackFloat",
    moduleProps: {
      promptText: "Helpful?",
      header: "Help us improve!",
      description: "How helpful is the Logan Plan 2025 portal?",
      hideOnPages: ["", "logan-plan-2025", "consultation-room", "feedback"],
    },
  },
];

export const pageConfig = [
  {
    id: "logan-plan-2025",
    name: "Hero block",
    children: [
      {
        // Position the video fixed for the page so it appears under all other items and
        // is scrollable to extend more than one page. Backrounds are required on pages in
        // SPA mode where video is not to be shown. They will sit on top of video (zIndex = -1)
        name: "Hero Video",
        columnProps: {},
        moduleComponentName: "HeroVideo",
        moduleProps: {
          videoName: "hero",
          style: { position: "fixed", top: 50, height: "100vh", zIndex: -1 },
          smallScreen: {
            style: {
              position: "fixed",
              top: 50,
              zIndex: -1,
              backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0% 100%), url(${landingBackground})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            },
          },
        },
      },
      {
        name: "Logan Plan 2025",
        columnProps: { xs: 12, className: "text-center text-dark" },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          alignCenter: true,
          heading: {
            priority: 1,
            style: {
              fontSize: 60,
              fontWeight: 700,
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              padding: "10px",
              borderRadius: "5px",
            },
            text: "Logan Plan 2025",
          },
          subheading: {
            style: {
              fontSize: 24,
              fontStyle: "italic",
              fontWeight: 700,
              color: "#420A42",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              padding: "10px",
              borderRadius: "5px",
            },
            text: "shared vision, sustainable future",
          },
        },
      },
      {
        name: "Card Link 1",
        columnProps: {
          md: 12,
          lg: 4,
          xl: 3,
          className: "px-4 mt-4 d-flex align-items-stretch text-dark",
        },
        moduleComponentName: "CardWithButton",
        moduleProps: {
          type: "image",
          fileName: "RiverdalePark",
          altText: "Riverdale Park",
          imgStyle: { height: 200, width: "auto", objectFit: "cover" },
          title: { text: "Planning for Logan's Growth" },
          text: {
            text: `
              Our planning scheme guides the way land is used and developed
              in the City of Logan.  It is the “book of rules” that tells us
              how to manage growth and infrastructure delivery, protect our
              green spaces, manage natural hazards, and provide great places
              for our diverse community to live in, work in and enjoy.
              Logan Plan 2025 will be a new planning scheme to help shape
              our growing city and create a prosperous future.
            `,
          },
          button: {
            text: "Learn More",
            buttonProps: {
              href: "#library",
              className: "float-right",
              variant: "logan-waratah",
            },
          },
        },
      },
      {
        name: "Card Link 2",
        columnProps: {
          md: 12,
          lg: 4,
          xl: 3,
          className: "px-4 mt-4 d-flex align-items-stretch text-dark",
        },
        moduleComponentName: "CardWithDocLinkButton",
        moduleProps: {
          type: "image",
          fileName: "ScreenShot2022-02-24",
          altText: "What’s changing preview image",
          imgStyle: { height: 200, width: "auto", objectFit: "cover" },
          title: { text: "What’s Changing?" },
          text: {
            text: `
              Our proposed new plan is designed to help us achieve the right development
              in the right places and protect what we love about Logan.  There are key
              changes in the way we manage growth in our residential neighbourhoods, in
              and around our centres, in our emerging communities and in other areas.
              There is also a stronger focus on improving design and landscaping/greening.
            `,
          },
          button: {
            text: "Learn more",
            buttonProps: {
              requiredDocument: "Key-policy-positions-summary",
              className: "float-right",
              variant: "logan-waratah",
            },
          },
        },
      },

      {
        name: "Card Link 3",
        columnProps: {
          md: 12,
          lg: 4,
          xl: 3,
          className: "px-4 mt-4 d-flex align-items-stretch text-dark",
        },
        moduleComponentName: "CardWithButton",
        moduleProps: {
          type: "image",
          fileName: "CafeonCusack-Jimboomba22",
          altText: "Cafe on Cusack",
          imgStyle: { height: 200, width: "auto", objectFit: "cover" },
          title: { text: "FAQs" },
          text: {
            dangerousHTMLContent: `
              To help you have your say in 2024, we invite you to learn more about our new
              plan for Logan’s future growth, and the process we’re following to deliver it.
              Scroll down to use the resources on this portal including our Frequently Asked
              Questions.  If you can’t find what you’re looking for please contact us on 07
              3412 3412 or by emailing
              <a href="mailto:LoganPlan2025@logan.qld.gov.au">LoganPlan2025@logan.qld.gov.au</a>.
            `,
          },
          button: {
            text: "See FAQs",
            buttonProps: {
              href: "#faqs",
              className: "float-right",
              variant: "logan-waratah",
            },
          },
        },
      },
      {
        name: "Welcome to country text",
        moduleComponentName: "SectionContent",
        columnProps: {
          sm: 8,
          xs: 12,
          className: "text-center mt-5 mb-3 font-weight-bold",
          style: {
            backgroundColor: "rgba(255, 255, 255, 1)",
            padding: "10px",
            borderRadius: "5px",
          },
        },
        moduleProps: {
          elementId: "landing-acknowledge",
        },
      },
    ],
    rowProps: {
      noGutters: true,
      // Center non-absolutely positioned content and make the row full height to overlay the video.
      className:
        "min-vh-100 py-4 py-md-0 d-flex align-content-center align-items-stretch justify-content-center",
    },
  },
  {
    id: "about-logan-plan",
    name: "About the project block",
    children: [
      {
        name: "About Logan Plan 2025",
        id: "About Logan Plan 2025 heading",
        columnProps: { xs: 12, className: "text-center text-dark mt-3" },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          alignCenter: true,
          heading: {
            priority: 1,
            style: {
              fontSize: 48,
              fontWeight: 500,
              padding: 5,
            },
            text: "About Logan Plan 2025",
          },
        },
      },
      {
        name: "About Logan plan text",
        id: "About Logan plan content",
        moduleComponentName: "SectionContent",
        columnProps: {
          sm: 8,
          xs: 12,
          className: "mt-2 mb-3",
          style: {
            backgroundColor: "rgba(255, 255, 255)",
            padding: "10px",
            borderRadius: "5px",
          },
        },
        moduleProps: {
          elementId: "about-logan-plan-content",
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className:
        "min-vh-100 d-flex align-content-center align-items-center justify-content-center",
      style: {
        backgroundImage: `
          linear-gradient(to bottom, rgba(255,255,255,0.6) 0% 100%),
          url(${aboutUsBackgroundImage})
        `,
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
    },
  },
  {
    id: "consultation-room",
    name: "Virtual Consultation Room block",
    columnProps: { className: "virtual-consultation-room-wrapper" },
    moduleComponentName: "VirtualConsultationRoom",
    moduleProps: {
      name: "virtual-consultation-room",
      scenes: [
        {
          id: "scene-1",
          geometries: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          limiter: {
            maxResolution: 1024,
            maxVFov: (100 * Math.PI) / 180,
            maxHFov: (120 * Math.PI) / 180,
          },
          viewParams: {
            yaw: 1.5860294550088048,
            pitch: 0,
            fov: 1.4134061960355204,
          },
          pulsateHotspots: true,
          hotspots: [
            {
              elementId: "info-1",
              position: { yaw: 1.39774682, pitch: 0.147487031 },
            }, // How to use the consultation room"
            {
              elementId: "video-1",
              position: { yaw: 1.57266641, pitch: -0.13129127 },
            }, // Video: https://www.youtube.com/embed?XVmxqIOSSCE Tooltip: "A short video introduction to Logan Plan 2025"
            {
              elementId: "video-2",
              position: { yaw: -1.27012817, pitch: 0.00419698 },
            }, // Video: https://www.youtube.com/embed?JgIsxgaANKI Tooltip: "A short video on our Planning Scheme"
            {
              elementId: "link-1",
              position: { yaw: 1.86374682, pitch: 0.147487031 },
            }, // Link to OCP FAQ page (same tab). Tooltip: "Frequently Asked Questions"
            {
              elementId: "link-2",
              position: { yaw: -3.03583532, pitch: -0.15146633 },
            }, // Link to OCP Library page (same tab). Tooltip: "Library of images, video and more"
            {
              elementId: "link-3",
              position: { yaw: -1.57390981, pitch: 0.09629724 },
            }, // Link to https://loganhub.com.au/interactive-mapping (new tab)
            {
              elementId: "link-4",
              position: { yaw: -0.37389274, pitch: 0.203405 },
            }, // Link to https://haveyoursaylogan.com.au/vision (new tab). Tooltip:  "Logan Community Vision"
            {
              elementId: "reveal-1",
              position: { yaw: 2.38214798, pitch: -0.054179493 },
            }, // Image:(attached in ticket) Tooltip: "We are growing"
            {
              elementId: "link-5",
              position: { yaw: -0.94780756, pitch: -0.04732803 },
            }, // Link to https://www.youtube.com/playlist?list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E
            {
              elementId: "reveal-3",
              position: { yaw: 0.82091357, pitch: 0.16593669 },
            }, // Image:(attached in ticket). Tooltip: "Our roadmap"
            {
              elementId: "link-6",
              position: { yaw: -2.23647174, pitch: -0.03967139 },
            }, // Logan Urban Design Framework
          ],
        },
      ],
      viewerParams: { controls: { scrollZoom: false } },
    },
    rowProps: { noGutters: true },
  },
  {
    id: "library",
    name: "Library block",
    children: [
      {
        name: "Library heading",
        columnProps: {
          xs: 12,
          sm: 12,
          md: 12,
          className: "mt-4 text-center text-dark",
        },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          alignCenter: true,
          heading: {
            priority: 1,
            text: "Library (videos and fact sheets)",
          },
          separator: { style: { height: 2, width: "40vw" } },
        },
      },
      {
        name: "Gallery",
        columnProps: { xs: 12, className: "mt-4" },
        moduleComponentName: "Gallery",
        moduleProps: {
          thumbnailProps: [
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "changing-in-neighbourhood",
              hoverText: "What's Changing... in our Neighbourhoods?",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=I8Gvu9t4N78&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=12",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "changing-in-centres",
              hoverText: "What's Changing... in our Centres?",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=9spZvN1VfeY&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=13",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "changing-in-growth-areas",
              hoverText: "What's Changing... in our Growth Areas?",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=TrUWqBxHNkI&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=14",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "Flooding",
              hoverText: "Flooding",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=mBlfZkZGVxE&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=6",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "LoganPlan2025",
              hoverText: "Logan Plan 2025",
              isForVideo: true,
              newTabUrl: "https://www.youtube.com/watch?v=-5qNq1kAwtU",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "Themes",
              hoverText: "Themes for Our Scheme",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=yBlA3AtBbX0&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=2",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "HowLoganIsPlanned",
              hoverText: "How Logan is Planned",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=85EDUO6AddA&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=4",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "Zoning",
              hoverText: "Zoning: What Goes Where",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=mM_xQ6Tzpm8&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=4",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "Overlays",
              hoverText: "Overlays:  Our Values and Constraints",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=aJHvhUnTokw&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=6",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "Infrastructure",
              hoverText: "Infrastructure for our Growing City",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=yTgbJWAMNJE&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=7",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "FundingNewLocalInfrastructure",
              hoverText: "Funding New Local Infrastructure",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=977HTf0ihcA&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=8",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "Places",
              hoverText: "Places for Our People",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=fzH6UUP02yg&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=9",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "DevelopmentAssessment",
              hoverText: "Development Assessment",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=PxD-qPXmcjA&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=10",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "PlanningFramework",
              hoverText: "Queensland's Planning Framework",
              isForVideo: true,
              newTabUrl:
                "https://www.youtube.com/watch?v=EYZrj59VSGI&list=PLz6nR8wicz6Q3_iDg8k9W4_aTz3ELrN8E&index=11",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "loganIcon",
              hoverText: "PIA - What is planning?",
              isForVideo: true,
              newTabUrl: "https://www.youtube.com/watch?v=G-CYiPELS48",
            },
            {
              columnProps: { xs: 12, sm: 6, md: 4, className: "my-2" },
              fileName: "UrbanDesignFramework",
              hoverText: "Logan Urban Design Framework",
              isForVideo: false,
              startingLightboxIndex: 0,
            },
          ],
          lightboxSources: [
            {
              title: "Logan Urban Design Framework",
              type: "video",
              src:
                "https://lcc-docs-planning.s3.ap-southeast-2.amazonaws.com/root/Online_Tools/LP25_OCP/Logan+Urban+Design+Framework.pdf",
              caption: "",
            },
          ],
        },
      },
      {
        name: "Fact Sheet Group",
        moduleComponentName: "EISChaptersGroup",
        rowProps: {
          className: "mt-5",
          noGutters: true,
        },
        moduleProps: {
          groupHeadingName: "Fact Sheets",
          groupHeadingId: "#fact-sheets",
          chapters: [
            {
              chapter:
                "A summary of key policy changes proposed in Logan Plan 2025.",
              tableFiles: [
                {
                  title: "Fact Sheet - Key policy changes",
                  document: "Key-policy-positions-summary",
                },
              ],
            },
            {
              chapter:
                "Information about each of the proposed policy changes, which were endorsed at a Special Meeting of Council 6 September 2022.",
              tableFiles: [
                {
                  title: "Fact Sheet - What is changing",
                  document: "what-is-changing",
                },
              ],
            },
            {
              chapter:
                "Managing our growth is one of five themes for our new planning scheme - learn about the key elements and let us know if we have it right.",
              tableFiles: [
                {
                  title: "Fact Sheet - Managing our Growth",
                  document: "Factsheet-Managing-our-growth",
                },
              ],
            },
            {
              chapter:
                "As our city grows it is important that we can preserve the lifestyle and communities we enjoy - take a look at this theme for our new planning scheme and tell us if we have our focus right.",
              tableFiles: [
                {
                  title: "Fact Sheet - Lifestyles and Community",
                  document: "Factsheet-Lifestyle-and-communities",
                },
              ],
            },
            {
              chapter:
                "We need to support business, investment  and jobs in our city - review our key focus areas for this theme and let us know what you think.",
              tableFiles: [
                {
                  title: "Fact Sheet - Thriving Economy",
                  document: "Factsheet-Thriving-economy",
                },
              ],
            },
            {
              chapter:
                "We value our 'green city'. There are key areas we want to focus on in this theme for our new planning scheme - learn more and give us your feedback.",
              tableFiles: [
                {
                  title: "Fact Sheet - Living With Nature",
                  document: "Factsheet-Living-with-nature",
                },
              ],
            },
            {
              chapter:
                "We need to deliver infrastructure that meets the needs of our growing city - have a look at the key elements of this theme and share your thoughts.",
              tableFiles: [
                {
                  title: "Fact Sheet - Infrastructure",
                  document:
                    "Factsheet-Sustainable-and-connected-infrastructure",
                },
              ],
            },
            {
              chapter:
                "A fact sheet from the Queensland Government explaining what a planning scheme is.",
              tableFiles: [
                {
                  title: "What is a planning scheme?",
                  document: "what-is-a-planning-scheme",
                },
              ],
            },
          ],
          showAsTable: true,
          tableProps: {
            headers: [
              {
                width: "25%",
                text: "Part",
              },
              { text: "Contents" },
            ],
          },
        },
        wrapWithContainer: true,
      },
    ],
    rowProps: {
      noGutters: true,
      className:
        "min-vh-100 py-4 py-md-0 d-flex align-content-center align-items-center justify-content-center",
      style: { background: "white" },
    },
    wrapWithContainer: true,
    wrappedRowProps: { className: "justify-content-center" },
  },
  {
    id: "my-property",
    name: "My Property block",
    children: [
      {
        name: "My Property heading",
        columnProps: { xs: 12, className: "text-center text-dark mt-3" },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          alignCenter: true,
          heading: {
            priority: 1,
            text: "My Property",
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              padding: "10px",
              borderRadius: "5px",
            },
          },
          subheading: {
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              fontSize: 16,
              fontWeight: 500,
              padding: 5,
              textAlign: "left",
              borderRadius: "5px",
            },
            text: [
              `
                The planning scheme guides what you can build on
                your property, how and where you can subdivide, the
                types of housing allowed and what kind of business
                can be run on the property. Use the search to find
                your property, or any other property in Logan, and
                then access further information using the options below.
                These options show information relating to the current
                planning scheme. When we share our draft Logan Plan 2025
                with our community in 2024 we will update this information
                to help you understand changes that may impact future
                development on your property.
              `,
              `
                Start typing the property address (e.g. 150 Wembley) and
                then pause to see possible matches.
              `,
            ],
          },
        },
      },
      {
        name: "property-search",
        columnProps: {
          xs: 12,
          sm: 10,
          className: "p-3 mt-4 d-flex align-items-stretch",
          style: {
            background: "white",
            borderRadius: "4px",
          },
        },
        moduleComponentName: "PropertySearch",
        moduleProps: {},
      },
      {
        name: "constraints-report",
        columnProps: {
          xs: 12,
          lg: 4,
          className: "px-4 mt-4 d-flex align-items-stretch",
        },
        moduleComponentName: "SectionContent",
        moduleProps: {
          elementId: "planning-report-tile",
        },
      },
      {
        name: "property-development",
        columnProps: {
          xs: 12,
          lg: 4,
          className: "px-4 mt-4 d-flex align-items-stretch",
        },
        moduleComponentName: "SectionContent",
        moduleProps: {
          elementId: "development-report-tile",
        },
      },
      {
        name: "property-index",
        columnProps: {
          xs: 12,
          lg: 4,
          className: "px-4 mt-4 d-flex align-items-stretch",
        },
        moduleComponentName: "SectionContent",
        moduleProps: {
          elementId: "planning-scheme-tile",
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className:
        "min-vh-100 py-4 py-md-0 d-flex align-content-center align-items-center justify-content-center",
      style: {
        backgroundImage: `
          linear-gradient(to bottom, rgba(255,255,255,0.7) 0% 100%),
          url(${propertyReportsBackground})
        `,
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
    },
    wrapWithContainer: true,
    wrappedRowProps: { className: "justify-content-center" },
  },
  {
    id: "get-involved",
    name: "Get Involved",
    children: [
      {
        name: "Get Involved",
        id: "Get Involved heading",
        columnProps: { xs: 12, className: "text-center text-dark mt-3" },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          alignCenter: true,
          heading: {
            priority: 1,
            style: {
              fontSize: 48,
              fontWeight: 500,
              padding: 5,
            },
            text: "Get Involved",
          },
        },
      },
      {
        name: "Survey",
        id: "Survey content",
        moduleComponentName: "SectionContent",
        columnProps: {
          sm: 8,
          xs: 12,
          className: "mt-2 mb-3",
          style: {
            backgroundColor: "rgba(255, 255, 255)",
            padding: "10px",
            borderRadius: "5px",
          },
        },
        moduleProps: {
          elementId: "survey",
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className:
        "min-vh-10 d-flex align-content-center align-items-center justify-content-center",
      style: {
        background: "white",
      },
    },
  },
  {
    id: "faqs",
    name: "FAQ block",
    children: [
      {
        name: "FAQ Section heading",
        columnProps: { xs: 12, className: "text-center text-dark mt-3" },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          alignCenter: true,
          heading: {
            priority: 1,
            text: "Frequently Asked Questions",
          },
        },
      },
      {
        name: "FAQ accordians",
        columnProps: { xs: 12, className: "p-0" },
        moduleComponentName: "SectionContent",
        moduleProps: {
          elementId: "faq-section",
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className:
        "min-vh-100 py-4 py-md-0 d-flex align-content-center align-items-center justify-content-center",
      style: {
        backgroundImage: `
          linear-gradient(to bottom, rgba(255,255,255,0.7) 0% 100%),
          url(${faqBackgroundImage})
        `,
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
    },
    wrapWithContainer: true,
  },
  {
    id: "planning-logans-growth",
    name: "Shaping Logans growth block",
    children: [
      {
        name: "Shaping Logans Growth heading",
        columnProps: {
          xs: 12,
          sm: 8,
          md: 6,
          className: "text-center text-dark mb-3",
        },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          alignCenter: true,
          heading: { priority: 1, text: "Planning for Logan's Growth" },
        },
      },
      {
        name: "Shaping Logans growth",
        id: "Shaping Logans growth content",
        moduleComponentName: "SectionContent",
        columnProps: {
          xs: 12,
        },
        moduleProps: {
          elementId: "logan-growth-content",
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className:
        "min-vh-100 py-4 py-md-0 d-flex align-content-center align-items-center justify-content-center",
      style: {
        backgroundImage: `
          linear-gradient(to bottom, rgba(255,255,255,0.6) 0% 100%),
          url(${growthBackgroundImage})
        `,
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
    },
    wrapWithContainer: true,
    wrappedRowProps: { className: "justify-content-center" },
  },
];

const config = [...onAllPages, ...pageConfig];

const SPAPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default SPAPage;
